<template>
    <admin-dashboard-layout>
        <v-card :loading="loading">
            <v-card-title>
                <v-row>
                    <v-col v-text="faq.title"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.faq.index'}" v-text="'return'"/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col md="6">
                            <v-text-field
                                label="Title"
                                v-model="faq.title"
                                :disabled="loading"
                            />
                        </v-col>
                        <v-col md="4">
                            <v-select
                                label="Parent"
                                v-model="faq.parent_id"
                                :item-value="'id'"
                                :item-text="'title'"
                                :disabled="loading"
                                :items="parents"/>
                        </v-col>
                        <v-col md="2">
                            <v-switch label="Active" v-model="faq.is_active" :disabled="loading"/>
                        </v-col>
                    </v-row>
                    <text-box
                        v-model="faq.text"
                        :text="faq.text"
                        :disabled="loading"
                        class="mb-10"/>
                    <v-btn @click="save" v-text="'Save'"/>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import Faq from "@/models/Faq";
import TextBox from "@/components/Form/TextBox";
import { mapMutations } from "vuex";

export default {
    components: {TextBox, AdminDashboardLayout},
    data() {
        return {
            faq: {
                title: null,
                text: null,
                parent_id: null
            },
            faqList: [],
            loading: false,
        }
    },
    computed: {
        parents() {
            return this.faqList.filter(item => item.id != this.$route.params.id)
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        async fetch() {
            this.loading = true;
            try {
                this.faqList = await Faq.get();

                if (this.$route.params.id) {
                    this.faq = await Faq.find(this.$route.params.id)
                }
            } catch (e) {
                this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
            } finally {
                this.loading = false;
            }
        },
        async save() {
            this.loading = true;

            try {
                const entity = new Faq(this.faq);
                this.faq = await entity.save();
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Item saved'})
            } catch (e) {
                this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
